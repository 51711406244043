<template>
  <div>
    <v-dialog v-model="show" width="800" @input="$emit('close')">
      <v-card>
        <v-card-text>
          <div class="row pt-1 border-bottom">
            <div class="col-md-12">
              <div class="d-flex justify-space-between align-center mt-2">
                <SvgIcon class="text-2xl font-semibold" text=" Products Wallet" style="color: black" >
                </SvgIcon>
                <v-btn  fab x-small class="shadow-0"     @click="closeModal">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
        </v-card-text>

        <v-card-text>
            <v-row class="mt-3">
              <div class="col-md-12">
                <table class="table border-collapse text-center">
                  <thead>
                  <tr class="opacity-70 tr-neon tr-rounded text-center">
                    <th>Name</th>
                    <th>Type</th>
                    <th>Quantity</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                  </thead>
                  <tbody v-if="products && products.length > 0">
                  <tr
                      v-for="(product, index) in products"
                      :key="index"
                  >
                    <td>
                      {{ product.name }}
                    </td>
                    <td>
                      {{ product.type }}
                    </td>
                    <td>
                      {{ product.quantity }}
                    </td>

                    <td>{{ product.status == 1 ? "Active" : "Inactive" }}</td>
                    <td>
                      <v-btn small @click="editProduct(product.id)"
                      ><v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                              v-bind="attrs"
                              v-on="on"
                              color="blue"
                              dense
                          >mdi-pencil</v-icon
                          >
                        </template>
                        Edit
                      </v-tooltip></v-btn
                      >
                      <v-btn small @click="deleteProduct(product.id)"
                      ><v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" color="red" dense
                          >mdi-delete</v-icon
                          >
                        </template>
                        Remove
                      </v-tooltip></v-btn
                      >
                    </td>
                  </tr>
                  </tbody>
                  <tbody v-else>
                  <tr>
                    <td colspan="6" class="text-center">No records</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
              color=" "
              class="ma-2  "
              text
              @click="closeModal"
          > Close </v-btn>

          <v-spacer/>
          <v-btn
              color=" darken-1"
              class="ma-2 white--text blue-color"
              text
              @click="showForm = true"
          >Add Products</v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>

    <v-dialog v-model="showForm" width="500">
        <v-card>
          <v-form ref="form">
            <v-card-text>
              <div class="row pt-1 border-bottom">
                <div class="col-md-12">
                  <div class="d-flex justify-space-between align-center mt-2">
                    <SvgIcon class="text-2xl font-semibold" text=" Wallet Product" style="color: black" >
                    </SvgIcon>
                    <v-btn  fab x-small class="shadow-0"     @click="closeAddEditForm">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-card-text>
            <v-card-text class="">
              <v-container>
                <v-row justify="center">
                  <v-col md="6">
                    <v-autocomplete
                        v-model="form_wallet_product.product_id"
                        outlined
                        item-text="name"
                        item-value="id"
                        label="Product"
                        :items="allProducts"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col md="6">
                    <v-text-field
                        v-model="form_wallet_product.quantity"
                        :rules="[
                      (v) => {
                        if (v) {
                          if (isNaN(v)) {
                            return 'Amount must be a number';
                          }
                        }
                        return true;
                      },
                    ]"
                        label="Quantity"
                        outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-btn
                  class="ma-2 "
                  text
                  @click="closeAddEditForm"
              >Close</v-btn
              >
              <v-spacer></v-spacer>

              <v-btn
                  color=" darken-1"
                  class="ma-2 white--text blue-color"
                  @click="saveProductWallet"
                  text
              >Save</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card>
    </v-dialog>
    <confirm-model
      v-bind="confirmModel"
      @confirm="confirmActions"
      @close="confirmModel.id = null"
    ></confirm-model>
  </div>
</template>

<script>
import SvgIcon from "@/components/Image/SvgIcon.vue";

export default {
  components: {SvgIcon},
  props: {
    show: { type: Boolean, default: false },
    customer_id: { type: Number, default: null },
  },
  data() {
    return {
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      products: [],
      showForm: false,
      form_wallet_product: {
        id: null,
        product: null,
        quantity: null,
      },
      allProducts: [],
    };
  },
  watch: {
    show(val) {
      // customer_id(val) {
      if (!val) {
        this.products = [];
      } else {
        this.getAllProducts();
        this.getProducts();
      }
    },
  },
  mounted() {},
  computed: {},
  methods: {
    deleteProduct(id) {
      this.confirmModel = {
        id: id,
        title: `Do you want to delete this from wallet?`,
        description: `This will remove selected item from this customers wallet. By clicking <b>Yes</b> you can confirm cancel operation`,
        type: "delete_product",
      };
    },
    getAllProducts() {
      this.$http
        .get(`venues/customers/products-wallet/get-all-products`)
        .then((response) => {
          if (response.status == 200 && response.data.status) {
            this.hideLoader();
            let data = response.data.data;
            this.allProducts = [];
            data.forEach((d) => {
              this.allProducts.push({
                id: d.id,
                name: d.name + ` (${d.product_type_name})`,
              });
            });
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },

    confirmActions(data) {
      this.showLoader("Loading...");

      this.$http
        .post(`venues/customers/products-wallet/delete/${data.id}`)
        .then((response) => {
          if (response.status == 200 && response.data.status) {
            this.hideLoader();
            this.confirmModel.id = null;
            this.getProducts();
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
      this.confirmModel.id = null;
    },
    getProducts() {
      this.showLoader("Loading...");

      this.$http
        .get(
          `venues/customers/products-wallet/get-customer/${this.customer_id}`
        )
        .then((response) => {
          if (response.status == 200 && response.data.status) {
            this.hideLoader();
            let data = response.data.data;
            this.products = [];
            console.log("data");
            console.log(data);
            data.forEach((d) => {
              this.products.push({
                id: d.id,
                name: d.product.name,
                quantity: d.quantity,
                type: d.product.product_type,
                status: d.status_id,
              });
            });
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
    closeModal() {
      this.$emit("close");
    },
    editProduct(index) {
      this.showForm = true;
      this.$http
        .get(`venues/customers/products-wallet/get-product/${index}`)
        .then((response) => {
          if (response.status == 200 && response.data.status) {
            let data = response.data.data;
            this.form_wallet_product = {
              id: data.id,
              product_id: data.product_id,
              quantity: data.quantity,
            };
            this.hideLoader();
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
    saveProductWallet() {
      this.showLoader();
      this.$http
        .post(
          `venues/customers/products-wallet` +
            (this.form_wallet_product.id
              ? "/" + this.form_wallet_product.id
              : ""),
          {
            product_id: this.form_wallet_product.product_id,
            quantity: this.form_wallet_product.quantity,
            customer_id: this.customer_id,
          }
        )
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.getProducts();
            this.closeAddEditForm();
            this.hideLoader();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    closeAddEditForm() {
      this.form_wallet_product = {
        id: null,
        product: null,
        quantity: null,
      };
      this.showForm = false;
    },
  },
};
</script>
<style scoped>
.personal-trainer_ic {
  background-image: url("../../../assets/images/nav_icon/personal-trainer-grey.png");
  padding: 10px;
  background-size: contain;
}

.credit_table table thead tr th {
  background: #066a8c !important;
  color: #fff !important;
}
.history_table thead {
  background-color: #ccc;
}
.history_table thead th {
  padding: 5px;
}
</style>
