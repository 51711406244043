<template>
  <v-dialog v-model="uploadDocumentDialog" width="830" @input="closeUploadDocumentDialog">
    <v-form ref="form" v-model="valid">
      <v-card>
        <v-card-text class="border-bottom">
          <div class="row pt-1 border-bottom">
            <div class="col-md-12">
              <div class="d-flex justify-space-between align-center mt-2">
                <SvgIcon class="text-2xl font-semibold" text="Upload Document" style="color: black" >
                </SvgIcon>
                <v-btn  fab x-small class="shadow-0" @click="closeUploadDocumentDialog">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
          <v-row dense class="mt-4">
            <div class="col-md-4">
              <label for="">ID Type *</label>
              <v-select
                  :rules="[(v) => !!v || 'Please select ID Proof Type']"
                  outlined
                  dense
                  hide-details="auto"
                  :menu-props="{ bottom: true, offsetY: true }"
                  item-value="id"
                  item-text="name"
                  :items="idProofTypes"
                  v-model="document.id_proof_type_id"
                  validate-on-blur
                  background-color="#fff"
                  class="q-autocomplete shadow-0"
              ></v-select>
            </div>

            <div class="col-md-4">
              <label for="">ID Number *</label>
              <v-text-field
                  :rules="[(v) => !!v || 'ID Number is required']"
                  class="q-text-field shadow-0" outlined dense
                  hide-details="auto"
                  v-model="document.id_proof_number"
              ></v-text-field>
            </div>
            <div class="col-md-4">
              <label for="">ID Proof</label>
              <v-file-input
                  :rules="[(v) => !!v || 'ID Attachment is required']"
                  v-model="document.id_proof"
                  class="q-text-field shadow-0"
                  :placeholder="`${ document.id_proof_path ? 'Change' : 'Select' }`"
                  validate-on-blur
                  prepend-icon=""
                  background-color="#fff"
                  outlined
                  dense
                  hide-details="auto"
              >
                <template v-slot:prepend-inner>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon
                          color="cyan"
                          v-if="document.id_proof_path"
                          @click="openFile(document.id_proof_path)"
                          v-on="on"
                      >
                        mdi-download-box
                      </v-icon>
                      <v-icon v-else v-on="on">
                        mdi-attachment
                      </v-icon>
                    </template>
                    <span v-if="document.id_proof_path"
                    >Download uploaded file</span
                    >
                    <span v-else>Upload ID Proof</span>
                  </v-tooltip>
                </template>
                <template v-slot:selection="{ index, text }">
                  <v-chip
                      v-if="index == 0"
                      color="cyan accent-4"
                      dark
                      label
                      small
                  ><span style="width: 38px" class="text-truncate">{{ text }}</span>
                  </v-chip>
                </template>
              </v-file-input>
            </div>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn
              color=" "
              class="ma-2  "
              text
              @click="closeUploadDocumentDialog"
          > Close </v-btn>
          <v-spacer></v-spacer>
          <v-btn
              color=" darken-1"
              class="ma-2 white--text blue-color"
              @click="saveUploadDocumentDialog"
              text
          >Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import SvgIcon from "@/components/Image/SvgIcon.vue";
export default {
  components: {
    SvgIcon,
  },
  props: {
    customerId: {type: Number, default: null},
    uploadDocumentDialog: {type: Boolean, default: false},
    isCustomer:{type:Boolean,default:false}
  },
  data() {
    return {
      document: { id_proof_path: null,id_proof_number: null, id_proof: null },
      valid: true,
    }
  },
  mounted() {
    if (this.$store.getters.getIdProofTypes.status == false) {
      this.$store.dispatch("loadIDProofTypes");
    }
  },
  computed: {
    maxLengthRule() {
      return (v) => (v && v.length <= 50) || 'Maximum 50 characters allowed'
    },
    idProofTypes() {
      return this.$store.getters.getIdProofTypes.data;
    },

  },
  methods: {
    closeUploadDocumentDialog(){
      this.document = { id_proof_path: null,id_proof_number: null, id_proof: null };
      this.$emit('closeDialog')
    },
    saveUploadDocumentDialog(){
      if (!this.$refs.form.validate()) {
        this.showError("Please fill all required fields");
        return;
      }

      let formData = new FormData();
      formData.append('id_proof_type_id', this.document.id_proof_type_id);
      formData.append('id_proof_number', this.document.id_proof_number)
      formData.append('customer_id', this.customerId);
      if (this.document.id_proof) {
        formData.append('id_proof', this.document.id_proof);
      }
      let url = `venues/customers/document/save`;
      this.showLoader("Loading...");
      this.$http.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data; boundary=${form._boundary}",
        },
      }).then((response) => {
          if (response.status == 200 && response.data.status) {
            this.document = { id_proof_path: null,id_proof_number: null, id_proof: null };
            this.$emit('saved');
          }
      }).catch((error) => {
          this.errorChecker(error);
      }).finally(() => {
        this.hideLoader()
      });
      this.$emit("closeDialogue");
    },
  }
}
</script>