<template>
<div>
  <v-dialog v-model="show" max-width="60%" @input="$emit('close')">
    <v-card>
      <v-card-text>
        <div class="row pt-1 border-bottom">
          <div class="col-md-12">
            <div class="d-flex justify-space-between align-center mt-2">
              <SvgIcon class="text-2xl font-semibold" text=" QPoints Transactions" style="color: black"></SvgIcon>
              <v-btn  fab x-small class="shadow-0" @click="closeModal">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </v-card-text>

      <v-card-text>
        <v-row class="mt-3">
          <div class="col-md-12">
            <table class="table border-collapse text-center">
                <thead>
                    <tr class="opacity-70 tr-neon tr-rounded text-center">
                        <th>Timestamp</th>
                        <th>Total Points</th>
                        <th>Remaining Points</th>
                        <th>Transaction</th>
                        <th>Status</th>
                        <th>From</th>
                        <th>To</th>
                    </tr>
                </thead>
                <tbody v-if="transactions && transactions.length > 0">
                    <tr v-for="(transaction, index) in transactions" :key="index">
                        <td>{{ transaction.timestamp | timeStamp }}</td>
                        <td>{{ transaction.points }}</td>
                        <td>{{ transaction.points_remaining }}</td>
                        <td :class="[transaction.transaction_type === 'd' ? 'red-font' : 'green-font']">
                        {{ transaction.transaction_type === 'd'?'Debit':'Credit' }}
                        </td>
                        <td>{{ transaction.status }}</td>
                        <td>{{ transaction.transfer_by }}</td>
                        <td>{{ transaction.transfer_to }}</td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td colspan="6" class="text-center">No records</td>
                    </tr>
                </tbody>
            </table>
          </div>
        </v-row>
        <v-row class="mt-3">
            <div class="col-md-12">
                <v-pagination
                    v-if="totalPages > 1"
                    v-model="page"
                    :length="totalPages"
                ></v-pagination>
            </div>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
              color=" "
              class="ma-2  "
              text
              @click="closeModal"
          > Close </v-btn>
          <v-spacer></v-spacer>
        <v-btn
            :disabled="points <= 0?true:false"
            color=" darken-1"
            class="ma-2 white--text blue-color"
            text
            @click="showForm = true"
        >Transfer Points</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>

  <v-dialog v-model="showForm" width="500">
    <v-card>
      <v-form ref="form">
        <v-card-text>
          <div class="row pt-1 border-bottom">
            <div class="col-md-12">
              <div class="d-flex justify-space-between align-center mt-2">
                <SvgIcon class="text-2xl font-semibold" text="Transfer Points" style="color: black" >
                </SvgIcon>
                <v-btn  fab x-small class="shadow-0" @click="closeAddEditForm">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
        </v-card-text>
        <v-card-text class="">
          <v-container>
            <v-row justify="center">
              <v-col md="6">
                <v-text-field
                    v-model="tPoints"
                    :rules="[
                        (v) => 
                            !!v || 'Points are required',
                            v => v > 1 || 'Points must be greater than 1',
                            v => v < points || 'Max avaible points '+points,
                    ]"
                    label="Transfer Points"
                    class="q-text-field shadow-0"
                    outlined
                    dense
                    hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col md="6">
                <v-autocomplete
                    v-model="transferTo"
                    :items="items"
                    :search-input.sync="search"
                    :loading="loading"
                    item-text="name"
                    item-value="id"
                    label="Search"
                    :debounce-search="500"
                     outlined
                    dense
                >
                <template v-slot:item="{ item }">
                    <v-list-item-content>
                        <v-list-item-title>{{ item.name }}</v-list-item-title>
                        <v-list-item-subtitle>{{ item.mobile }}</v-list-item-subtitle>
                    </v-list-item-content>
                    </template>
                </v-autocomplete>
              </v-col>

            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn
              class="ma-2 "
              text
              @click="closeAddEditForm"
          >Close</v-btn
          >
          <v-spacer></v-spacer>

          <v-btn
              color=" darken-1"
              class="ma-2 white--text blue-color"
              @click="transferPoints"
              text
          >Confirm</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
  <confirm-model
      v-bind="confirmModel"
      @confirm="confirmActions"
      @close="confirmModel.id = null"
  ></confirm-model>

</div>
</template>
<script>
import SvgIcon from "@/components/Image/SvgIcon.vue";

export default {
  components: {SvgIcon},
  props: {
    show: { type: Boolean, default: false },
    customerId: { type: Number, default: null },
    points: { type: Number, default: 0 }
  },
  data() {
    return {
        page: 1,
        totalPages: 1,
        perPage: 10,
        tPoints:0,
        confirmModel: {
            id: null,
            title: null,
            description: null,
        },
        showForm:false,
        transactions: [],
        transferTo: null,
        items: [],
        search: '',
        loading: false,
        fetchOnSelect: true,
    };
  },
  watch: {
    show(val) {
      if (!val) {
        this.transactions = [];
      }else {
        this.getTransactions();
      }
    },
    page() {
      this.getTransactions();
    },
    search(newVal) {
      if (newVal && newVal.length > 2) {
        this.handleChange(newVal);
      } else {
        this.items = [];
      }
    }
  },
  methods:{
    closeAddEditForm(){
        this.transferTo = null;
        this.tPoints = 0;
        this.items = [];
        this.showForm = false;
    },
    getTransactions() {
        this.showLoader("Loading...");
        this.$http.get(`venues/customers/qpoints/${this.customerId}`,{
            params: {
                per_page: this.perPage,
                page: this.page
            }
        }).then((response) => {
            if (response.status == 200 && response.data.status) {
                this.hideLoader();
                const records = response.data;
                this.transactions = records.data;
                this.page = records.current_page ? Number(records.current_page) : 1;
                this.totalPages = records.total_pages
            }else{
                this.transactions = [];
            }
        }).catch((error) => {
            this.hideLoader();
            this.errorChecker(error);
        });
    },

    transferPoints(){
        if (!this.$refs.form.validate()) {
            this.showError("Please fill all required fields");
            return;
        }
        if(!this.transferTo){
            this.showError("Customer not found");
            return;
        }
        if(this.points < this.tPoints){
            this.showError("Transfer points can not exceed by available points");
            return;
        }
        const data = {
            'transfer_to' : this.transferTo,
            'transfer_by' : this.customerId,
            'transfer_points': this.tPoints
        };
        this.showLoader("Loading...");
        this.$http.post(`venues/customers/qpoints/transfer`,data)
        .then((response) => {
            if (response.status == 200 && response.data.status) {
                this.hideLoader();
                this.closeAddEditForm();
                this.getTransactions();
            }
            this.hideLoader();
        })
        .catch((error) => {
            this.hideLoader();
            this.errorChecker(error);
        });
    },
    closeModal() {
      this.$emit("close");
    },
    confirmActions(data) {
      console.log(data);
    },
    fetchData(name) {
        if(name == undefined || name == null){
            return false;
        }
        this.loading = true;
        this.$http
        .get(`venues/customers/vc-search?name=${name}`)
        .then((response) => {
            if (response.status == 200) {
                this.isSearchLoading = false;
                let data = response.data.data;
                this.items = data.length > 0 ? data : [];
                this.fetchOnSelect = false;
                this.$forceUpdate();
            }else{
                this.items = [];
            }
            this.loading = false;
        })
        .catch((error) => {
            this.loading = false;
            this.errorChecker(error);
        })
    },
    handleChange() {
        if (this.fetchOnSelect && this.search !== '') {
            this.fetchData(this.search);
        } else {
            this.fetchOnSelect = true;
        }
    },
  }


}
</script>
<style scoped>
.red-font{
  color: red;
}
.green-font{
  color: green;
}
</style>